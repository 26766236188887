@import url("../../styles/variables.less");

.custom-layout-container {
    &__header {
        padding: 0 1rem !important;
        background-color: black !important;
    }

    .custom-main-content {
        margin: 0 16px;
    }

    .custom-inner-content {
        padding: 24px;
        min-height: 360px;
    }
    .custom-bread-crumb-container {
        margin: 16px 0;
    }
    min-height: 100vh;

    #components-layout-demo-side .logo {
        height: 32px;
        margin: 16px;
        background: rgba(255, 255, 255, 0.3);
    }
    .site-layout {
        min-height: 100vh;

        &__menu {
            border-right: none;
            background-color: black;

            .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
                background-color: black;
            }

            li {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 19rem;
                border-top: 2px solid @metal-blue;
                padding: 2rem;
                margin: 0 !important;

                &:active {
                    background-color: black !important;
                }

                @media (max-width: 767px) {
                    justify-content: flex-end;
                    height: 11rem;
                }

                .ant-menu-title-content {
                    flex: none !important;
                    opacity: 0.4;
                }

                &:not(:first-child) {
                    margin: 0 auto !important;
                    border-top: 1px solid @sider-border;
                    width: 95%;
                }

                img {
                    height: 4rem;
                    width: 4rem;
                    opacity: 0.4;
                }

                span {
                    margin: 0;
                    height: 5rem;
                }

                .ant-menu-title-content {
                    height: 4rem;
                }

                a {
                    color: @white-color;
                    font-weight: 500;
                    font-size: 1.8rem;
                }

                &:active {
                    background-color: black !important;
                }

                .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
                    background-color: black !important;
                }
            }
        }

        &__sider {
            background-color: black;
            overflow-x: hidden;
            // padding: 0 0.5rem;
        }
    }
    .custom-main-layout-footer {
        text-align: center;
    }
}

.site-layout .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: black !important;
}

.site-layout .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .ant-menu-title-content,
.site-layout .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected img {
    opacity: 1 !important;
}

.site-layout .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected::after {
    opacity: 0 !important;
}

@primary-color: #A49786;