@import url("../../styles/variables.less");

.SearchInput-container {
    // padding-top: 0.5rem;
    display: flex;

    @media (max-width: 767px) {
        padding-left: 0;
    }

    &__search {
        display: flex;
        margin-bottom: 2.625rem;

        .ant-input-affix-wrapper {
            background-color: @white-color;
            border: none;
            height: 3.8rem;
            width: 43.2rem;

            border-radius: 2rem;

            .ant-input {
                background-color: @white-color;

                &::placeholder {
                    color: @text-color;
                }
            }
        }
    }
}


@primary-color: #A49786;