@import url("../../styles/variables.less");

.login-container {
    background-image: url("/assets/images/login-img.png");
    position: relative;
    z-index: 1;
    height: 100vh;

    &__colored {
        color: @primary-color;
        text-align: center;
    }

    &__caption {
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0.8;
        padding-bottom: 20rem;

        &__container {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-direction: column;

            &__title {
                display: flex;
                align-items: center;
                font-size: 2rem;
                font-weight: 500;
                color: @white-color;
            }

            &__text {
                display: flex;
                justify-content: center;
                &-paragraph {
                    font-size: 1.6rem;
                    font-weight: 400;
                    color: @white-color;
                    width: 50%;
                    text-align: center;
                }
            }

            &__author {
                display: flex;
                justify-content: center;
                flex-direction: column;
                font-size: 1.6rem;
                color: @white-color;
            }
        }
    }

    &__information {
        background-color: @white-color;
        padding: 4rem;

        &__logo-container {
            margin-bottom: 8rem;
        }

        &__title {
            color: @primary-color;
            font-size: 1.6rem;
            font-weight: 500;
        }

        &__subtitle {
            font-size: 1.3rem;
            line-height: 2rem;
            font-weight: 400;
        }

        &__btn {
            width: 95%;
        }

        &__sign-in__container {
            display: flex;
            flex-direction: column;
            margin-bottom: 20rem;

            &__text {
                width: 100%;
                text-align: center;
                margin-bottom: 1.3rem;
                color: @text-color-secondary !important;
            }

            &__icons {
                display: flex;
                justify-content: center;

                &__icon-container {
                    width: 3.2rem;
                    height: 3.2rem;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    &-facebook {
                        background-color: @facebook-blue;
                    }

                    &-twitter {
                        margin: 0 1rem;
                        background-color: @twitter-blue;
                    }

                    &-google {
                        background-color: @google-peach;
                    }
                }
            }
        }

        &__sign-up__container {
            text-align: center;
            margin-bottom: 15rem;

            span {
                color: @text-color-secondary;
            }
        }

        &__trademark {
            text-align: center;
        }
    }

    @media (max-width: 767px) {
        background-image: none;
    }
}
// .login-container__img-container {
//     position: relative;
//     z-index: 999;
// }

@primary-color: #A49786;