@import url("../../styles/variables.less");

.header-container {
    padding-left: 2.5rem;
    // padding-top: 0.5rem;
    display: flex;

    @media (max-width: 767px) {
        padding-left: 0;
    }

    &__logo {
        width: 17.5rem;
    }

    &__search {
        width: 40rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .ant-input-affix-wrapper {
            background-color: @topbar-search-color;
            border: none;
            height: 3.8rem;
            width: 23.3rem;
            border-radius: 2rem;

            .ant-input {
                background-color: @topbar-search-color;

                &::placeholder {
                    color: @text-color;
                }
            }
        }

        @media (max-width: 767px) {
            display: none;
        }
    }

    &__btn {
        border: none !important;
        background-color: transparent !important;
        box-shadow: none !important;
    }

    &__profile {
        display: flex;
        // justify-self: flex-end;
        margin-left: auto;

        &-information {
            display: flex;
        }
    }

    &__modal {
        width: 75rem !important;

        .ant-modal-content {
            height: 50rem;
            .ant-modal-close {
                .ant-modal-close-x {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 2rem;
                    width: 2rem;
                    border: 2px solid @paragraph-color;
                    border-radius: 50%;
                    margin-top: 2.4rem;
                    margin-right: 1.5rem;

                    .anticon {
                        color: @paragraph-color;
                        font-size: 1rem;
                        // font-weight: 700 !important;
                    }
                }
            }
        }
    }
}

@primary-color: #A49786;