@import url("/src/styles/variables.less");

.subscription-plans-detail-container {
    &__table {
        margin-bottom: 2rem;

        .custom-table-grid-view-container {
            .custom-table-container {
                .ant-spin-nested-loading {
                    .ant-spin-container {
                        .ant-table {
                            .ant-table-title {
                                font-size: 1.5rem;
                                font-weight: 600;
                                line-height: 2.25rem;
                            }
                        }
                    }
                }
            }
        }
    }

    &__btn-container {
        display: flex;
        justify-content: center;

        &__btn {
            width: 39rem;
            height: 3.6rem;
        }
    }

    &__status {
        width: 10rem;
        height: 3rem;
        border-radius: 2rem !important;
        cursor: default !important;

        &-active {
            color: @active-text !important;
            background-color: @active-green !important;
        }

        &-blocked {
            color: @blocked-text !important;
            background-color: @blocked-red !important;
        }
    }

    &__card-container {
        margin-bottom: 3rem;
    }

    &__action-container {
        img:not(:last-child) {
            margin-right: 2rem;
        }

        img {
            cursor: pointer;
        }
    }
}

@primary-color: #A49786;