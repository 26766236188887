.change-password-modal {
    padding: 0 15rem;
    &__title {
        font-size: 2.4rem;
        font-weight: 500;
        line-height: 3.6rem;
    }

    .ant-form-vertical {
        .ant-form-item {
            margin-bottom: 4rem;
        }
    }

    &__submit {
        width: 100%;
        margin-top: 3rem;
    }
}

@primary-color: #A49786;