@import "~antd/dist/antd.less";
@import url("/src/utility-classes.less");

html {
    font-size: 62.5%;
}

.custom-flex {
    display: flex;
}

.ant-layout-sider-trigger {
    display: none;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

@primary-color: #A49786;