@import url("/src/styles/variables.less");

.refferal-code-container {
    &__table {
        margin-bottom: 2rem;

        .custom-table-grid-view-container {
            .custom-table-container {
                .ant-spin-nested-loading {
                    .ant-spin-container {
                        .ant-table {
                            .ant-table-title {
                                font-size: 1.5rem;
                                font-weight: 600;
                                line-height: 2.25rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .filterDateBtn:hover,
    .filterDateBtn:active,
    .filterDateBtn:focus {
        background-color: #fff;
        border: 1px solid #d9d9d9;
    }
    .filterDateBtn {
        border: 1px solid #d9d9d9;
        background-color: #fff;
        width: 147px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
            color: #bfbfbf;
            font-size: 12px;
        }
    }

    .FilterAndCreateContainer {
        margin-bottom: 2.625rem;
    }

    &__modal {
        .datePickerStyling {
            width: 100%;
            height: 40px;
            border-radius: 4px;
            border: 1px solid @text-color-secondary;
        }
        .datePickerStyling input::placeholder {
            color: @text-color-secondary;
            font-size: 13px;
        }
        .ant-form-item-label label {
            font-weight: 500;
            color: #495057;
            margin-bottom: 8px;
        }

        span.ant-checkbox-inner {
            width: 24px;
            height: 24px;
        }

        .__editrefferalTitle {
            margin-bottom: 20px;
        }

        .ant-input-affix-wrapper {
            height: 40px;
            border-radius: 4px;
            border: 1px solid @text-color-secondary;

            .ant-input {
                background-color: transparent; // Removed quotes
                height: auto;

                &::placeholder {
                    color: @text-color-secondary;
                    font-size: 13px;
                }
            }
        }

        &__input__label {
            font-weight: 500;
            color: @text-color-secondary;
            margin-bottom: 8px;
        }

        input {
            height: 40px;
            border-radius: 4px;
            border: 1px solid @text-color-secondary;

            &::placeholder {
                color: @text-color-secondary;
                font-size: 13px;
            }
        }

        .ant-btn {
            width: 224px;
            height: 36px;
            margin-top: 2rem;
            border-radius: 3px;
        }

        .checkBoxStyle {
            margin-bottom: 10px;
        }

        // .RefferalDuration {
        //     .ant-select-selection-search-input {
        //         height: 40px !important;
        //     }

        //     .ant-select-selection-item {
        //         line-height: 40px !important;
        //     }

        //     .ant-select-selection-placeholder {
        //         line-height: 40px !important;
        //     }
        // }
        .ant-select-selection-item {
            line-height: 40px !important;
        }

        .ant-select-selection-placeholder {
            line-height: 40px !important;
        }
        .ant-select {
            .ant-select-selection-placeholder {
                color: @text-color-secondary;
                font-size: 13px;
            }

            .ant-select-selector {
                height: 40px !important;
                border-radius: 4px;
                border: 1px solid #495057 !important;
            }
            
        }
        .deleteModal {
            text-align: center;
        }
        .deleteModal img {
            margin-bottom: 2rem;
        }
        .deleteModal p {
            color: #2a2b2d;
            font-size: 20px;
            margin-bottom: 0px;
        }
    }

    &__btn-container {
        display: flex;
        justify-content: center;

        &__btn {
            width: 39rem;
            height: 3.6rem;
        }
    }

    &__status {
        width: 10rem;
        height: 3rem;
        border-radius: 2rem !important;
        cursor: default !important;

        &-active {
            color: @active-text !important;
            background-color: @active-green !important;
        }
        Ø &-blocked {
            color: @blocked-text !important;
            background-color: @blocked-red !important;
        }
    }

    &__card-container {
        margin-bottom: 3rem;
    }

    &__action-container {
        img:not(:last-child) {
            margin-right: 2rem;
        }

        img {
            cursor: pointer;
        }
    }
    &__dateCreatedModalContainer {
        .ant-select-selection-item,.ant-select-selection-placeholder {
            line-height: 40px !important;
        }
        .ant-select-selection-search-input {
            height: 40px !important;
            line-height: 40px !important;
        }
        .selectStyling {
            width: 100%;
        }
        .ant-input-affix-wrapper {
            height: 40px;
            border-radius: 4px;
            border: 1px solid @text-color-secondary;

            .ant-input {
                background-color: transparent; // Removed quotes
                height: auto;

                &::placeholder {
                    color: @text-color-secondary;
                    font-size: 13px;
                }
            }
        }

        .ant-select {
            .ant-select-selection-placeholder {
                color: @text-color-secondary;
                font-size: 13px;
            }

            .ant-select-selector {
                height: 40px !important;
                border-radius: 4px;
                border: 1px solid #495057 !important;
            }
        }
        .datePickerStyling {
            width: 100%;
            height: 40px;
            border-radius: 4px;
            border: 1px solid #495057;
        }
        .datePickerStyling input::placeholder {
            color: @text-color-secondary;
            font-size: 13px;
        }
        .label {
            font-weight: 500;
            font-size: 13px;
            color: #495057;
        }
        .btnStyle {
            width: 224px;
            height: 36px;
            margin-top: 2rem;
            border-radius: 3px;
        }
        .btnStyleReset {
            width: 224px;
            height: 36px;
            margin-top: 2rem;
            border-radius: 3px;
            // margin-left: 1.5rem;
            background-color: black;
            float: right;
        }
    }
}


@primary-color: #A49786;