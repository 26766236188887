@import url("/src/styles/variables.less");

.card-container {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 3rem;
    border-radius: 0.5rem;

    @media screen and (max-width: 1199px) {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 1rem;
    }

    &__icon {
        @media screen and (max-width: 1199px) {
            margin-bottom: 1rem;
        }
    }

    &__information {
        &__title {
            color: @text-color;
            font-size: 1.2rem;
            font-weight: 500;
            line-height: 2rem;
        }
    }

    &__count {
        font-size: 2rem;
        line-height: 3rem;
        font-weight: 500;
        color: @text-color-secondary;
    }
}

@primary-color: #A49786;