@import url("/src/styles/variables.less");

.subscription-plans-container {
    &__table {
        margin-bottom: 2rem;

        .custom-table-grid-view-container {
            .custom-table-container {
                .ant-spin-nested-loading {
                    .ant-spin-container {
                        .ant-table {
                            .ant-table-title {
                                font-size: 1.5rem;
                                font-weight: 600;
                                line-height: 2.25rem;
                            }
                        }
                    }
                }
            }
        }
    }
    .FilterAndCreateContainer {
        margin-bottom: 2.625rem;
    }
    &__modal {
        .PhoneInput{
            border-radius: 4px;
            border: 1px solid #495057;
            padding: 0px 10px;
            input, input:focus-visible {
                border: none !important;
                outline: none;
            }
        }
        .ant-input[disabled]{
            border: 1px solid #495057;
            color: #74788d;
        }
        .ant-form-item-label label {
            font-weight: 500;
            color: #495057;
            margin-bottom: 8px;
        }
        span.ant-checkbox-inner {
            width: 24px;
            height: 24px;
        }
        .__editSubscriptionTitle {
            margin-bottom: 20px;
        }
        .ant-input-affix-wrapper {
            height: 40px;
            border-radius: 4px;
            border: 1px solid @text-color-secondary;
            .ant-input[disabled]{
                border: none;
            }
            .ant-input {
                background-color: "transparent";
                height: auto;

                &::placeholder {
                    color: @text-color-secondary;
                    font-size: 13px;
                }
            }
            .ant-input-prefix {
                color: #495057;
            }
        }
        &__input__label {
            font-weight: 500;
            color: @text-color-secondary;
            margin-bottom: 8px;
        }
        input {
            height: 40px;
            border-radius: 4px;
            border: 1px solid @text-color-secondary;

            &::placeholder {
                color: @text-color-secondary;
                font-size: 13px;
            }
        }
        .ant-btn {
            width: 224px;
            height: 36px;
            margin-top: 2rem;
            border-radius: 3px;
        }
        .checkBoxStyle {
            margin-bottom: 10px;
        }
        .checkBoxStyleCheck {
            margin-bottom: 0px;
        }
        .SubscriptionDuration {
            .ant-select-selection-search-input {
                height: 40px !important;
            }
            .ant-select-selection-item {
                line-height: 40px !important;
            }
            .ant-select-selection-placeholder {
                line-height: 40px !important;
            }
        }
        .ant-select {
            .ant-select-selection-placeholder {
                color: @text-color-secondary;
                font-size: 13px;
            }
            .ant-select-selector {
                height: 40px !important;
                border-radius: 4px;
                border: 1px solid #495057 !important;
            }
        }
        .deleteModal {
            text-align: center;
        }
        .deleteModal img {
            margin-bottom: 2rem;
        }
        .deleteModal p {
            color: #2a2b2d;
            font-size: 20px;
            margin-bottom: 0px;
        }
    }
    &__btn-container {
        display: flex;
        justify-content: center;

        &__btn {
            width: 39rem;
            height: 3.6rem;
        }
    }

    &__status {
        width: 10rem;
        height: 3rem;
        border-radius: 2rem !important;
        cursor: default !important;

        &-active {
            color: @active-text !important;
            background-color: @active-green !important;
        }

        &-blocked {
            color: @blocked-text !important;
            background-color: @blocked-red !important;
        }
    }

    &__card-container {
        margin-bottom: 3rem;
    }

    &__action-container {
        img:not(:last-child) {
            margin-right: 2rem;
        }

        img {
            cursor: pointer;
        }
    }
}


@primary-color: #A49786;