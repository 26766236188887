@import url("/src/styles/variables.less");

.mb-4 {
    margin-bottom: 4rem;
}

.mb-5 {
    margin-bottom: 5rem;
}

.ml-2 {
    margin-left: 2rem;
}

.ml-1 {
    margin-left: 1rem;
}

.mr-1 {
    margin-right: 1rem;
}

.ml-point-5 {
    margin-left: 0.5rem;
}

.mr-point-5 {
    margin-right: 0.5rem;
}

.white-color {
    color: @white-color;
}

.text-center {
    text-align: center;
}

.cursor-pointer {
    cursor: pointer;
}

.w-100 {
    width: 100%;
}
