.dashboard-container {
    &__table {
        margin-bottom: 2rem;

        .custom-table-grid-view-container {
            .custom-table-container {
                .ant-spin-nested-loading {
                    .ant-spin-container {
                        .ant-table {
                            .ant-table-title {
                                font-size: 1.5rem;
                                font-weight: 600;
                                line-height: 2.25rem;
                            }
                        }
                    }
                }
            }
        }
    }

    &__btn-container {
        display: flex;
        justify-content: center;

        &__btn {
            width: 39rem;
            height: 3.6rem;
        }
    }

    &__card-container {
        margin-bottom: 3rem;
    }
}

@primary-color: #A49786;